var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-lg-5" }, [
          _c("h1", [_vm._v("パスワード再設定")]),
          _c("p", [
            _vm._v(
              "登録しているメールアドレスを入力して「送信する」をクリックしてください。弊社より認証コードが記載されたメールを送信します。"
            )
          ]),
          _vm.message
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(_vm._s(_vm.message))
              ])
            : _vm._e(),
          _vm.errMessage
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("システムエラーが発生しました。"),
                _c(
                  "div",
                  [
                    _vm._v(
                      "しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが"
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/inquiry_form/input" } },
                      [_vm._v("こちら")]
                    ),
                    _vm._v("よりをお問い合わせください。")
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "needs-validation",
              attrs: { novalidate: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.exec($event)
                }
              }
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mail,
                      expression: "mail"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: "メールアドレス",
                    required: ""
                  },
                  domProps: { value: _vm.mail },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mail = $event.target.value
                    }
                  }
                }),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("メールアドレスを入力してください")
                ])
              ]),
              _c("input", {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "submit",
                  value: "送信する",
                  disabled: _vm.isDisabled
                }
              })
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }