<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-5
          h1 パスワード再設定
          p 登録しているメールアドレスを入力して「送信する」をクリックしてください。弊社より認証コードが記載されたメールを送信します。
          .alert.alert-danger(v-if="message") {{ message }}
          .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
            div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
              router-link(to="/inquiry_form/input") こちら
              |よりをお問い合わせください。
          form.needs-validation(@submit.prevent="exec" novalidate)
            .form-group
              input.form-control(type="text" placeholder="メールアドレス" v-model="mail" required)
              .invalid-feedback メールアドレスを入力してください
            input.btn.btn-primary(type="submit" value="送信する" v-bind:disabled="isDisabled")
</template>

<script>
import {
  Config,
  CognitoIdentityCredentials
} from 'aws-sdk'
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      message: '',
      mail: '',
      isDisabled: false,
      errMessage: false,
    }
  },
  created() {
    const poolData = {
      UserPoolId : process.env.VUE_APP_COGNITO_USER_POOL_ID,
      ClientId : process.env.VUE_APP_COGNITO_CLIENT_ID
    }
    this.userPool = new CognitoUserPool(poolData)
  },
  methods: {
    toHalfWidth(value) {
      return value.replace(/[Ａ-Ｚａ-ｚ０-９．＠＋－]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      })
    },
    exec(e) {
      this.isDisabled = true

      this.message = ''

      this.errMessage = false

      this.mail = this.mail.trim()

      this.mail = this.toHalfWidth(this.mail)

      //エラー処理
      if (e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation();
        this.isDisabled = false
        return
      }

      let userData = {
        Username : this.mail.toLowerCase(),
        Pool : this.userPool
      }
      
      this.$user.cognito = new CognitoUser(userData)

      //パスワード忘れ、認証コード送信
      this.$user.cognito.forgotPassword({
        onSuccess: (result) => {
          // 処理結果OK
          this.$router.push({ name: 'user_password_reset_input', query: {mail: this.mail}})
          this.isDisabled = false
        },
        onFailure: (err) => {
          // 処理結果Error
          if(err.code == 'LimitExceededException' || err.code == 'TooManyRequestsException') {
            this.message = 'しばらく時間をおいてから再度お試しください。'
          }else {
            this.errMessage = true
          }
          this.isDisabled = false
          return
        }       
      })

    }
  }
}
</script>